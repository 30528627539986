import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ adminMessages }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFolders, setGalleryFolders] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isLoadingGallery, setIsLoadingGallery] = useState(false);
  const [galleryError, setGalleryError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentGalleryFolder, setCurrentGalleryFolder] = useState(null);
  const [currentGalleryCategory, setCurrentGalleryCategory] = useState(null);
  const [folderImages, setFolderImages] = useState([]);
  const [characterTabActive, setCharacterTabActive] = useState('details');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [characterSheetJson, setCharacterSheetJson] = useState(null);
  const [activeCharacterTab, setActiveCharacterTab] = useState('summary');
  const characterSheetRef = useRef(null);
  const [passwordData, setPasswordData] = useState({ current: '', new: '', confirm: '' });
  const [localAdminMessages, setLocalAdminMessages] = useState(adminMessages || []);
  const [avatar, setAvatar] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [avatarError, setAvatarError] = useState('');
  const [loadingCharacterSheet, setLoadingCharacterSheet] = useState(false);
  const [characterSheetError, setCharacterSheetError] = useState('');
  const [showUploadForm, setShowUploadForm] = useState(false);
  // Add state to track minimized messages
  const [minimizedMessages, setMinimizedMessages] = useState([]);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Fetch the user's avatar and character sheet on component mount
  useEffect(() => {
    fetchAvatar();
    fetchCharacterSheet();
    fetchAdminMessages();
  }, []);

  // Fetch avatar when component mounts
  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      fetchAvatar();
      // If user is adventurer, fetch character sheet
      if (currentUser?.role === 'adventurer') {
        fetchCharacterSheet();
      }
    }

    return () => {
      setIsMounted(false);
    };
  }, [isMounted, currentUser]);

  // Fetch gallery categories when gallery tab is selected
  useEffect(() => {
    if (activeTab === 'gallery' && galleryCategories.length === 0 && !isLoadingGallery) {
      fetchGalleryCategories();
    }
  }, [activeTab, galleryCategories.length, isLoadingGallery]);

  // Fetch user's avatar from the server
  const fetchAvatar = async () => {
    try {
      if (!currentUser || !isMounted) return;
      
      const response = await fetch(`${API_URL}/avatar`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok && isMounted) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setAvatarUrl(imageUrl);
      } else {
        console.error('Failed to fetch avatar');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  // Fetch user's character sheet
  const fetchCharacterSheet = async () => {
    try {
      if (!isMounted) return;
      
      setIsLoadingCharacter(true);
      setCharacterError('');
      // Clear the current character sheet data before fetching new data
      setCharacterSheet(null);
      
      const response = await fetch(`${API_URL}/character-sheet`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch character sheet');
      }
      
      const data = await response.json();
      if (isMounted) {
        setCharacterSheet(data);
      }
    } catch (err) {
      if (isMounted) {
        console.error('Error fetching character sheet:', err);
        setCharacterError(err.message || 'An error occurred while fetching character sheet');
      }
    } finally {
      if (isMounted) {
        setIsLoadingCharacter(false);
      }
    }
  };

  // Fetch gallery categories
  const fetchGalleryCategories = async () => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      const response = await fetch(`${API_URL}/gallery/categories`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setGalleryCategories(data.categories);
      } else {
        setGalleryError('Failed to load gallery categories');
      }
    } catch (error) {
      console.error('Error fetching gallery categories:', error);
      setGalleryError('An error occurred while loading the gallery');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Fetch images from a specific folder
  const fetchFolderImages = async (category, folderPath) => {
    try {
      setIsLoadingGallery(true);
      setGalleryError('');
      
      console.log(`Fetching images from: category=${category}, folder=${folderPath}`);
      
      let url;
      if (!folderPath || folderPath === '') {
        // For direct image folders, don't include the folderPath in the URL
        url = `${API_URL}/gallery/folder/${category}`;
      } else {
        url = `${API_URL}/gallery/folder/${category}/${folderPath}`;
      }
      
      console.log('Request URL:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log(`Received ${data.images.length} images:`, data.images);
        setGalleryImages(data.images);
        setCurrentFolder({ category, path: folderPath });
      } else {
        const errorData = await response.text();
        console.error('Failed to load folder images:', errorData);
        setGalleryError(`Failed to load folder images: ${errorData}`);
      }
    } catch (error) {
      console.error('Error fetching folder images:', error);
      setGalleryError('An error occurred while loading the images');
    } finally {
      setIsLoadingGallery(false);
    }
  };

  // Return to folders view
  const handleBackToFolders = () => {
    setCurrentFolder(null);
    setGalleryImages([]);
  };

  // Handle folder click
  const handleFolderClick = (category, folder) => {
    console.log('Folder clicked:', category, folder);
    
    // Handle direct image folders (which have empty path)
    const folderPath = folder.path || '';
    
    console.log(`Requesting images for category=${category}, folderPath=${folderPath}`);
    fetchFolderImages(category, folderPath);
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely using an iframe to isolate the content
  const createIframeSrc = (content) => {
    const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
    body {
      margin: 0;
      padding: 0;
      font-family: 'Crimson Text', serif;
      color: #e2e8f0;
      background-color: transparent;
      font-size: 14px;
      line-height: 1.5;
      overflow-wrap: break-word;
    }
    a { color: #ffd700; text-decoration: underline; }
    h1, h2, h3 { color: #ffd700; font-family: 'Cinzel', serif; margin-top: 10px; margin-bottom: 10px; }
    ul, ol { padding-left: 20px; margin-bottom: 10px; }
    img { max-width: 100%; height: auto; }
    p { margin-bottom: 10px; }
    
    /* Force content to be fully visible */
    html, body {
      min-height: 100%;
      height: auto !important;
      overflow: visible;
    }
    
    * {
      max-width: 100%;
    }
  </style>
  <script>
    // Send height to parent when content changes
    function updateHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage({ type: 'resize', height: height }, '*');
    }
    
    window.onload = function() {
      updateHeight();
      // Additional check after images and other resources load
      setTimeout(updateHeight, 500);
    };
    
    // Watch for DOM changes and update height
    const observer = new MutationObserver(updateHeight);
    observer.observe(document.body, { 
      childList: true, 
      subtree: true,
      attributes: true,
      characterData: true
    });
  </script>
</head>
<body>${content || ''}</body>
</html>`;
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  // Backwards compatibility wrapper for the old renderHtml function
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      const formData = new FormData();
      formData.append('avatar', file);
      
      const response = await fetch(`${API_URL}/avatar/upload`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        throw new Error('Failed to upload avatar');
      }
      
      // Fetch the new avatar
      await fetchAvatar();
      
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setUploadError('Failed to upload avatar');
    } finally {
      setIsUploading(false);
      e.target.value = '';
    }
  };

  // Format date string
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Handle tab selection
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle logout
  const handleLogout = () => {
    logout();
  };

  // Function to refresh character sheet data
  const refreshCharacterSheet = async () => {
    setIsLoadingCharacter(true);
    setCharacterSheet(null);
    setCharacterError('');
    await fetchCharacterSheet();
  };

  // Handle character sheet upload
  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.name.endsWith('.json')) {
      setCharacterError('Invalid file type. Please upload a JSON file.');
      return;
    }

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterError('File too large. Maximum size is 5MB.');
      return;
    }

    setIsLoadingCharacter(true);
    setCharacterError('');
    // Clear the current character sheet data before uploading new data
    setCharacterSheet(null);
    
    // Read the file contents first to ensure it's valid JSON
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Validate JSON first
          const fileContent = event.target.result;
          const parsedData = JSON.parse(fileContent); 
          
          // Basic validation to check if it's a character sheet
          if (!parsedData.name) {
            throw new Error('Invalid character sheet format: Missing character name');
          }
          
          // If JSON is valid, proceed with the upload
          const formData = new FormData();
          formData.append('characterSheet', file);

          const response = await fetch(`${API_URL}/adventurer/upload/character-sheet`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: formData
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload character sheet');
          }

          // Successfully uploaded, now fetch the character sheet
          await fetchCharacterSheet();
        } catch (error) {
          console.error('Error processing or uploading character sheet:', error);
          setCharacterError(error.message || 'Invalid JSON file or failed to upload character sheet');
          setIsLoadingCharacter(false);
        }
      };
      
      reader.onerror = () => {
        setCharacterError('Error reading the file');
        setIsLoadingCharacter(false);
      };
      
      reader.readAsText(file);
    } catch (error) {
      console.error('Error uploading character sheet:', error);
      setCharacterError(error.message || 'Failed to upload character sheet');
      setIsLoadingCharacter(false);
    }
  };

  // Add this new helper function before renderCharacterContent
  const getDisplayValue = (key, value) => {
    // Special debug handling for race/background/class: examine the full object
    // console.log(`Getting display value for ${key}:`, value);
    
    // If value doesn't exist, return Unknown
    if (value === null || value === undefined || value === '') return 'Unknown';
    
    // Special handling for specific keys
    if (key === 'class') {
      // First look for class items which are more reliable
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItems = characterSheet.items.filter(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItems.length > 0) {
          // Join multiple class names if there are several
          return classItems.map(item => item.name).join(' / ');
        }
      }
    }
    
    if (key === 'race') {
      // Check if we have a race item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const raceItem = characterSheet.items.find(item => 
          item.type === 'race' || item.type === 'ancestry' || item.type === 'heritage'
        );
        if (raceItem && raceItem.name) return raceItem.name;
      }
      
      // Otherwise try to extract from various locations
      if (characterSheet.system && characterSheet.system.traits && characterSheet.system.traits.race) {
        return characterSheet.system.traits.race;
      }
    }
    
    if (key === 'background') {
      // Check if we have a background item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const bgItem = characterSheet.items.find(item => item.type === 'background');
        if (bgItem && bgItem.name) return bgItem.name;
      }
    }
    
    if (key === 'alignment') {
      // Common alignment codes
      const alignments = {
        'lg': 'Lawful Good',
        'ng': 'Neutral Good',
        'cg': 'Chaotic Good',
        'ln': 'Lawful Neutral',
        'n': 'Neutral',
        'cn': 'Chaotic Neutral',
        'le': 'Lawful Evil',
        'ne': 'Neutral Evil',
        'ce': 'Chaotic Evil'
      };
      
      // Check if this is a known alignment code
      if (typeof value === 'string' && alignments[value.toLowerCase()]) {
        return alignments[value.toLowerCase()];
      }
    }
    
    if (key === 'originalClass') {
      // Check for class item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItem = characterSheet.items.find(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItem) return classItem.name;
      }
    }
    
    // If value is a simple string that's not an ID (no periods or @), return it
    if (typeof value === 'string') {
      // Clean up compendium references
      if (value.includes('@Compendium')) {
        const match = value.match(/\[([^\]]+)\]/);
        if (match && match[1]) {
          const parts = match[1].split('.');
          if (parts.length > 0) {
            // Get the last part and clean it
            const name = parts[parts.length - 1]
              .replace(/[0-9]+$/, '') // Remove trailing numbers
              .replace(/([A-Z])/g, ' $1') // Add spaces before capital letters
              .replace(/^[^a-zA-Z]+/, '') // Remove leading non-alphabetic chars
              .trim();
              
            if (name) return name.charAt(0).toUpperCase() + name.slice(1);
          }
        }
      }
      
      // For dnd5e.X.Y style IDs (e.g., dnd5e.races.elf)
      if (value.includes('.')) {
        const parts = value.split('.');
        if (parts.length > 0) {
          return parts[parts.length - 1]
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
      }
      
      // For unrecognized strings that look like IDs
      if (/^[A-Za-z0-9]{10,}$/.test(value)) {
        // This looks like an ID - try to find the item
        if (characterSheet.items && Array.isArray(characterSheet.items)) {
          // Look for an item with this ID or name containing this
          const item = characterSheet.items.find(item => 
            item._id === value || 
            (item.name && item.name.includes(value))
          );
          if (item && item.name) return item.name;
        }
        
        // If we can't find an item, this might be a code - return Unknown
        return 'Unknown';
      }
      
      // For regular strings, just return as is
      return value;
    }
    
    // For numeric values
    if (typeof value === 'number') {
      return value.toString();
    }
    
    // For anything else, return string representation
    return String(value);
  };

  // Handle tab selection within character sheet
  const handleCharacterTabClick = (tab) => {
    setCharacterTabActive(tab);
  };

  // Add event listener for tab clicks from the HTML content
  useEffect(() => {
    // Event delegation to handle tab clicks
    function handleTabClick(event) {
      // Find if the click was on a tab button or one of its children
      let target = event.target;
      while (target && !target.classList?.contains('character-tab-button')) {
        // If we reach the document body or an element with no parent, break
        if (target === document.body || !target.parentElement) {
          return;
        }
        target = target.parentElement;
      }
      
      // If we found a tab button
      if (target && target.classList.contains('character-tab-button')) {
        const tabName = target.getAttribute('data-tab');
        if (tabName) {
          handleCharacterTabClick(tabName);
        }
      }
    }
    
    // Add the event listener to the document
    document.addEventListener('click', handleTabClick);
    
    return () => {
      document.removeEventListener('click', handleTabClick);
    };
  }, []);

  // Render character sheet content
  const renderCharacterSheet = () => {
    if (isLoadingCharacter) {
      return <div className="loading-character">Loading character sheet...</div>;
    }

    if (characterError) {
      return <div className="character-error">{characterError}</div>;
    }

    if (!characterSheet) {
      return (
        <div className="no-character-sheet">
          <h4>No Character Sheet Found</h4>
          <p>Upload your character sheet to see it displayed here.</p>
          
          {/* Character sheet tabs - even when there's no sheet */}
          <div className="character-sheet-tabs">
            <button 
              className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('details')}
              data-tab="details"
            >
              Details & Abilities
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('equipment')}
              data-tab="equipment"
            >
              Equipment & Currency
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('spells')}
              data-tab="spells"
            >
              Spells
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('biography')}
              data-tab="biography"
            >
              Biography
            </button>
          </div>
          
          {/* Tab content container */}
          <div className="character-sheet-tab-content">
            {/* Tab 1: Details */}
            <div className={`character-tab-pane ${characterTabActive === 'details' ? 'active' : ''}`} id="details-tab">
              <div className="no-items">No character details available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 2: Equipment */}
            <div className={`character-tab-pane ${characterTabActive === 'equipment' ? 'active' : ''}`} id="equipment-tab">
              <div className="no-items">No equipment data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 3: Spells */}
            <div className={`character-tab-pane ${characterTabActive === 'spells' ? 'active' : ''}`} id="spells-tab">
              <div className="no-items">No spell data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 4: Biography */}
            <div className={`character-tab-pane ${characterTabActive === 'biography' ? 'active' : ''}`} id="biography-tab">
              <div className="no-items">No biography information available. Upload a character sheet to see information here.</div>
            </div>
          </div>
          
          <label className="upload-character-button">
            Upload Character Sheet
            <input
              type="file"
              accept=".json"
              onChange={handleCharacterSheetUpload}
            />
          </label>
        </div>
      );
    }

    // Create the character header HTML with name and portrait
    const characterHeader = () => {
      return (
        <div className="character-header">
          <h2 className="character-name">{characterSheet.name || 'Unnamed Character'}</h2>
          <div className="character-portrait">
            {avatarUrl ? (
              <img src={avatarUrl} alt={characterSheet.name || 'Character'} />
            ) : (
              <div className="character-portrait-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64">
                  <path fill="#8B0000" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                </svg>
              </div>
            )}
          </div>
        </div>
      );
    };

    // Render the tabs and the appropriate tab content based on active tab state
    return (
      <div className="character-sheet-content">
        {/* Character content header */}
        {characterHeader()}
        
        {/* Character sheet tabs */}
        <div className="character-sheet-tabs">
          <button 
            className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('details')}
            data-tab="details"
          >
            Details & Abilities
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('equipment')}
            data-tab="equipment"
          >
            Equipment & Currency
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('spells')}
            data-tab="spells"
          >
            Spells
          </button>
          <button 
            className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
            onClick={() => handleCharacterTabClick('biography')}
            data-tab="biography"
          >
            Biography
          </button>
        </div>
        
        {/* Tab content container - only render the active tab */}
        <div className="character-sheet-tab-content">
          {characterTabActive === 'details' && (
            <div className="character-tab-pane active" id="details-tab" 
                 dangerouslySetInnerHTML={{ __html: renderDetailsTab() }} />
          )}
          
          {characterTabActive === 'equipment' && (
            <div className="character-tab-pane active" id="equipment-tab" 
                 dangerouslySetInnerHTML={{ __html: renderEquipmentTab() }} />
          )}
          
          {characterTabActive === 'spells' && (
            <div className="character-tab-pane active" id="spells-tab" 
                 dangerouslySetInnerHTML={{ __html: renderSpellsTab() }} />
          )}
          
          {characterTabActive === 'biography' && (
            <div className="character-tab-pane active" id="biography-tab" 
                 dangerouslySetInnerHTML={{ __html: renderBiographyTab() }} />
          )}
        </div>
        
        <div className="character-sheet-refresh">
          <button 
            className="refresh-button" 
            onClick={refreshCharacterSheet}
            disabled={isLoadingCharacter}
          >
            {isLoadingCharacter ? 'Refreshing...' : 'Refresh Character Sheet'}
          </button>
        </div>
      </div>
    );
  };

  // Function to render just the details tab content
  const renderDetailsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Character details section
    html += '<div class="character-details-section">';
    html += '<h3>CHARACTER DETAILS</h3>';
    
    // Create a grid layout for character details
    html += '<div class="character-details-grid">';
    
    // Create individual detail boxes with labels above values
    html += `
      <div class="detail-box">
        <div class="detail-label">Race</div>
        <div class="detail-value">${getDisplayValue('race', characterSheet.system?.details?.race)}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Background</div>
        <div class="detail-value">${getDisplayValue('background', characterSheet.system?.details?.background)}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Alignment</div>
        <div class="detail-value">${getDisplayValue('alignment', characterSheet.system?.details?.alignment)}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">XP</div>
        <div class="detail-value">${getDisplayValue('xp', characterSheet.system?.details?.xp?.value)}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Original Class</div>
        <div class="detail-value">${getDisplayValue('originalClass', characterSheet.system?.details?.originalClass)}</div>
      </div>
    `;
    
    html += '</div>'; // End character details grid
    html += '</div>'; // End character details section
    
    // Abilities section
    html += '<div class="abilities-section">';
    html += '<h3>ABILITIES</h3>';
    html += '<div class="abilities-container">';
    
    if (characterSheet.system?.abilities) {
      Object.entries(characterSheet.system.abilities).forEach(([key, ability]) => {
        const mod = getAbilityModifier(ability.value);
        const sign = mod >= 0 ? '+' : '';
        
        html += `
          <div class="ability-score">
            <div class="ability-name">${key.toUpperCase()}</div>
            <div class="ability-circle">
              <span class="ability-value">${ability.value !== undefined && ability.value !== null ? ability.value : ''}</span>
            </div>
            <div class="ability-modifier">${sign}${mod}</div>
            ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
          </div>
        `;
      });
    }
    
    html += '</div>'; // End abilities container
    html += '</div>'; // End abilities section
    
    // Combat Stats Section - EXPANDED with all stats
    html += '<div class="combat-section">';
    html += '<h3>Combat</h3>';
    html += '<div class="combat-stats">';
    
    // HP section
    if (characterSheet.system?.attributes?.hp) {
      const hp = characterSheet.system.attributes.hp;
      const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
      
      html += `
        <div class="combat-stat hp-container">
          <div class="stat-name">Hit Points</div>
          <div class="hp-values">
            <span class="current-hp">${hp.value || 0}</span>
            <span class="hp-separator">/</span>
            <span class="max-hp">${hp.max || 0}</span>
          </div>
          <div class="hp-bar">
            <div class="hp-fill" style="width: ${hpPercentage}%"></div>
            <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
          </div>
          ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
        </div>
      `;
    }
    
    // Core combat stats
    const combatStats = [
      { name: 'Armor Class', value: characterSheet.system?.attributes?.ac?.value !== undefined ? characterSheet.system?.attributes?.ac?.value : '' },
      { name: 'Initiative', value: characterSheet.system?.attributes?.init?.total !== undefined ? characterSheet.system?.attributes?.init?.total : '' },
      { name: 'Speed', value: characterSheet.system?.attributes?.movement?.walk !== undefined ? `${characterSheet.system?.attributes?.movement?.walk} ft` : '' },
      { name: 'Proficiency', value: characterSheet.system?.attributes?.prof?.value !== undefined ? characterSheet.system?.attributes?.prof?.value : '' }
    ];
    
    combatStats.forEach(stat => {
      html += `
        <div class="combat-stat">
          <div class="stat-name">${stat.name}</div>
          <div class="stat-value">${stat.value}</div>
        </div>
      `;
    });
    
    // Add other movement types if they exist
    if (characterSheet.system?.attributes?.movement) {
      const movement = characterSheet.system.attributes.movement;
      Object.entries(movement).forEach(([key, value]) => {
        if (key !== 'walk' && value) {
          html += `
            <div class="combat-stat">
              <div class="stat-name">${key.charAt(0).toUpperCase() + key.slice(1)} Speed</div>
              <div class="stat-value">${value} ft</div>
            </div>
          `;
        }
      });
    }
    
    // Add hit dice if they exist
    if (characterSheet.system?.attributes?.hd) {
      const hd = characterSheet.system.attributes.hd;
      html += `
        <div class="combat-stat">
          <div class="stat-name">Hit Dice</div>
          <div class="stat-value">${hd.value || 0}/${hd.max || 0} ${hd.die || 'd8'}</div>
        </div>
      `;
    }
    
    // Add death saves if they exist
    if (characterSheet.system?.attributes?.death) {
      const death = characterSheet.system.attributes.death;
      html += `
        <div class="combat-stat death-saves">
          <div class="stat-name">Death Saves</div>
          <div class="death-save-boxes">
            <div class="success-saves">
              <span>Successes: </span>
              <span class="save-box ${death.success >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 3 ? 'filled' : ''}"></span>
            </div>
            <div class="failure-saves">
              <span>Failures: </span>
              <span class="save-box ${death.failure >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 3 ? 'filled' : ''}"></span>
            </div>
          </div>
        </div>
      `;
    }
    
    html += '</div>'; // End combat stats
    html += '</div>'; // End combat section
    
    // Skills Section - EXPANDED with all skills and details
    html += '<div class="skills-section">';
    html += '<h3>Skills</h3>';
    html += '<div class="skills-container">';
    
    if (characterSheet.system) {
      // Calculate proficiency bonus based on level
      const level = characterSheet.system?.details?.level || 1;
      const proficiencyBonus = Math.floor((level - 1) / 4) + 2;
      
      // Define skills in D&D character sheet order with full names
      const dndSkillOrder = [
        { key: 'acr', name: 'Acrobatics', ability: 'dex' },
        { key: 'ani', name: 'Animal Handling', ability: 'wis' },
        { key: 'arc', name: 'Arcana', ability: 'int' },
        { key: 'ath', name: 'Athletics', ability: 'str' },
        { key: 'dec', name: 'Deception', ability: 'cha' },
        { key: 'his', name: 'History', ability: 'int' },
        { key: 'ins', name: 'Insight', ability: 'wis' },
        { key: 'itm', name: 'Intimidation', ability: 'cha' },
        { key: 'inv', name: 'Investigation', ability: 'int' },
        { key: 'med', name: 'Medicine', ability: 'wis' },
        { key: 'nat', name: 'Nature', ability: 'int' },
        { key: 'prc', name: 'Perception', ability: 'wis' },
        { key: 'prf', name: 'Performance', ability: 'cha' },
        { key: 'per', name: 'Persuasion', ability: 'cha' },
        { key: 'rel', name: 'Religion', ability: 'int' },
        { key: 'slt', name: 'Sleight of Hand', ability: 'dex' },
        { key: 'ste', name: 'Stealth', ability: 'dex' },
        { key: 'sur', name: 'Survival', ability: 'wis' }
      ];
      
      // Render skills in the specified order
      dndSkillOrder.forEach(orderedSkill => {
        // Get the skill data from the character sheet
        const skills = characterSheet.system.skills || {};
        const skill = skills[orderedSkill.key];
        
        // Get the corresponding ability score
        const abilityKey = orderedSkill.ability;
        const abilityScore = characterSheet.system.abilities?.[abilityKey]?.value || 10;
        const abilityMod = getAbilityModifier(abilityScore);
        
        if (skill) {
          // Calculate the skill modifier
          // value: 0 = not proficient, 1 = proficient, 2 = expertise
          const profValue = skill.value || 0;
          const isProficient = profValue > 0;
          const hasExpertise = profValue > 1;
          
          // Calculate the total modifier: ability modifier + (proficiency value × proficiency bonus)
          const totalMod = abilityMod + (profValue * proficiencyBonus);
          const formattedMod = totalMod >= 0 ? `+${totalMod}` : `${totalMod}`;
          
          // Display the ability name in uppercase
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient ${isProficient ? 'active' : ''}"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
              ${hasExpertise ? '<div class="skill-expertise">Expertise</div>' : ''}
            </div>
          `;
        } else {
          // If skill is not defined, just show the base ability modifier
          const formattedMod = abilityMod >= 0 ? `+${abilityMod}` : `${abilityMod}`;
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
            </div>
          `;
        }
      });
    } else {
      // If no skills at all, create a placeholder
      html += '<div class="no-items">No skills found</div>';
    }
    
    html += '</div>'; // End skills container
    html += '</div>'; // End skills section
    
    // Features section
    html += '<div class="features-section">';
    html += '<h3>Features & Traits</h3>';
    html += '<div class="features-list">';
    
    if (characterSheet.items) {
      const features = characterSheet.items.filter(item => 
        item.type === 'feat' || item.type === 'feature'
      );
      
      if (features.length === 0) {
        html += '<div class="no-items">No features found</div>';
      } else {
        // Sort by source (class, race, etc.) - FIX sorting to handle non-string values
        features
          .sort((a, b) => {
            // Ensure sourceA and sourceB are strings
            const sourceA = String(a.system?.source || '');
            const sourceB = String(b.system?.source || '');
            if (sourceA === sourceB) return String(a.name || '').localeCompare(String(b.name || ''));
            return sourceA.localeCompare(sourceB);
          })
          .forEach(item => {
            const source = item.system?.source ? ` (${item.system.source})` : '';
            
            html += `
              <div class="feature-item">
                <div class="feature-name">${item.name}${source}</div>
                <div class="feature-description">
                  ${item.system?.description?.value 
                    ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
              </div>
            `;
          });
      }
    } else {
      html += '<div class="no-items">No features found</div>';
    }
    
    html += '</div>'; // End features list
    html += '</div>'; // End features section
    
    return html;
  };

  // Function to render just the equipment tab content
  const renderEquipmentTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Currency section if it exists - MOVED BEFORE equipment
    if (characterSheet.system?.currency) {
      html += '<div class="currency-section">';
      html += '<h3>Currency</h3>';
      html += '<div class="currency-container">';
      
      const currency = characterSheet.system.currency;
      const currencyTypes = [
        { key: 'pp', name: 'Platinum' },
        { key: 'gp', name: 'Gold' },
        { key: 'ep', name: 'Electrum' },
        { key: 'sp', name: 'Silver' },
        { key: 'cp', name: 'Copper' }
      ];
      
      currencyTypes.forEach(type => {
        html += `
          <div class="currency-item">
            <span class="currency-name">${type.name}</span>
            <span class="currency-value">${currency[type.key] || 0}</span>
          </div>
        `;
      });
      
      html += '</div>'; // End currency container
      html += '</div>'; // End currency section
    }
    
    // Equipment section - NOW AFTER currency
    html += '<div class="equipment-section">';
    html += '<h3>Equipment</h3>';
    html += '<div class="equipment-list">';
    
    if (characterSheet.items) {
      // Group equipment by type
      const equipment = characterSheet.items.filter(item => 
        ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'].includes(item.type)
      );
      
      if (equipment.length === 0) {
        html += '<div class="no-items">No equipment found</div>';
      } else {
        // Sort equipment by type and name
        equipment
          .sort((a, b) => {
            // Ensure type and name are strings
            const typeA = String(a.type || '');
            const typeB = String(b.type || '');
            const nameA = String(a.name || '');
            const nameB = String(b.name || '');
            
            if (typeA === typeB) return nameA.localeCompare(nameB);
            return typeA.localeCompare(typeB);
          })
          .forEach(item => {
            const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
            const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
            const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
            
            html += `
              <div class="equipment-item">
                <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                <div class="equipment-type">${itemType}${weight}</div>
                <div class="equipment-description">
                  ${item.system?.description?.value 
                    ? `<div>${item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
            `;
            
            // Add weapon-specific properties
            if (item.type === 'weapon' && item.system) {
              const weapon = item.system;
              html += '<div class="weapon-properties">';
              
              if (weapon.damage) {
                html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
              }
              
              if (weapon.attackBonus) {
                html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
              }
              
              if (weapon.properties) {
                const props = Object.entries(weapon.properties)
                  .filter(([, value]) => value === true)
                  .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                  .join(', ');
                  
                if (props) {
                  html += `<div class="weapon-props">Properties: ${props}</div>`;
                }
              }
              
              if (weapon.range) {
                html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
              }
              
              html += '</div>'; // End weapon properties
            }
            
            html += '</div>'; // End equipment item
          });
      }
    } else {
      html += '<div class="no-items">No equipment found</div>';
    }
    
    html += '</div>'; // End equipment list
    html += '</div>'; // End equipment section
    
    return html;
  };

  // Function to render just the spells tab content
  const renderSpellsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Spells section
    html += '<div class="spells-section">';
    html += '<h3>Spells</h3>';
    
    // Spellcasting Ability
    if (characterSheet.system?.attributes?.spellcasting) {
      const spellAbility = characterSheet.system.attributes.spellcasting.ability;
      if (spellAbility) {
        html += `<div class="spellcasting-ability">Spellcasting Ability: ${spellAbility.toUpperCase()}</div>`;
      }
    }
    
    // Spell Slots
    if (characterSheet.system?.spells) {
      html += '<div class="spell-slots-container">';
      
      // Display spell slots by level
      for (let level = 1; level <= 9; level++) {
        const slotKey = `spell${level}`;
        if (characterSheet.system.spells[slotKey]) {
          const slots = characterSheet.system.spells[slotKey];
          html += `
            <div class="spell-slot-level">
              <div class="slot-level">Level ${level}</div>
              <div class="slot-values">
                <span class="slots-used">${slots.value || 0}</span>
                <span class="slots-separator">/</span>
                <span class="slots-total">${slots.max || 0}</span>
              </div>
            </div>
          `;
        }
      }
      
      html += '</div>'; // End spell slots container
    }
    
    // Group spells by level
    html += '<div class="spell-list-by-level">';
    
    // Initialize an array to hold spells by level
    const spellsByLevel = {};
    for (let i = 0; i <= 9; i++) {
      spellsByLevel[i] = [];
    }
    
    // Sort spells into level buckets
    console.log('Character Items:', characterSheet.items); // Log all items
    console.log('Character System:', characterSheet.system); // Log the system data
    
    // Check for spells in different possible locations
    if (Array.isArray(characterSheet.items)) {
      const spellItems = characterSheet.items.filter(item => 
        item.type === 'spell' || 
        item.type === 'power' || // Some systems use "power" instead of "spell"
        (item.flags && item.flags.spellbook) // Check for spellbook flags
      );
      console.log('Found Spell Items:', spellItems); // Log the filtered spell items
      
      spellItems.forEach(spell => {
        // Try to get level from different possible locations
        const level = spell.system?.level || 
                      spell.level || 
                      spell.data?.level || 
                      (spell.flags?.spellbook?.level) || 
                      0;
        spellsByLevel[level].push(spell);
      });
    } else {
      console.error('Character sheet items is not an array or is undefined:', characterSheet.items);
      // Add a placeholder message for no spells
      html += '<div class="no-items">No spells found or items data is invalid</div>';
    }
    
    // Create a section for each level that has spells
    for (let level = 0; level <= 9; level++) {
      if (spellsByLevel[level].length > 0) {
        const levelName = level === 0 ? 'Cantrips' : `Level ${level} Spells`;
        
        html += `<div class="spell-level-group">`;
        html += `<h4 class="spell-level-header">${levelName}</h4>`;
        
        // Sort spells alphabetically within each level
        spellsByLevel[level]
          .sort((a, b) => String(a.name || '').localeCompare(String(b.name || '')))
          .forEach(spell => {
            // Get additional spell details
            const prepared = spell.system?.preparation?.prepared ? ' (Prepared)' : '';
            const school = spell.system?.school ? ` - ${spell.system.school}` : '';
            const castTime = spell.system?.activation?.type ? ` - ${spell.system.activation.type}` : '';
            
            html += `
              <div class="spell-item">
                <div class="spell-name">${spell.name || 'Unknown Spell'}${prepared}</div>
                <div class="spell-meta">${castTime}${school}</div>
                <div class="spell-description">
                  ${spell.system?.description?.value 
                    ? `<div>${spell.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
              </div>
            `;
          });
        
        html += `</div>`; // End spell level group
      }
    }
    
    html += '</div>'; // End spell list by level
    html += '</div>'; // End spells section
    
    return html;
  };

  // Function to render just the biography tab content
  const renderBiographyTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Biography section
    html += '<div class="biography-section">';
    html += '<h3>Biography</h3>';
    
    // Biography section - check multiple possible locations
    console.log('Biography Data:', characterSheet.system?.details?.biography); // Log biography data
    console.log('Alternative Biography:', characterSheet.system?.description); // Log alternative location
    
    // Check multiple possible locations for biography data
    let bio = null;
    if (characterSheet.system?.details?.biography) {
      bio = characterSheet.system.details.biography;
    } else if (characterSheet.system?.description) {
      bio = characterSheet.system.description;
    } else if (characterSheet.system?.details?.description) {
      bio = characterSheet.system.details.description;
    } else if (characterSheet.description) {
      bio = characterSheet.description;
    }
    
    if (bio) {
      // Log each biography field
      console.log('Biography object found:', bio);
      
      // Check for bio.value or alternative fields
      if (bio.value || bio.text || bio.public) {
        const bioText = bio.value || bio.text || bio.public || '';
        html += `
          <div class="biography-entry">
            <h4>Biography</h4>
            <div class="biography-text">${bioText.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for appearance field
      if (bio.appearance) {
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${bio.appearance.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.physical) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${bio.physical.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for trait field
      if (bio.trait) {
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.trait.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.traits) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.traits.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.personality) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${bio.personality.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for ideal field
      if (bio.ideal) {
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${bio.ideal.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.ideals) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${bio.ideals.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for bond field
      if (bio.bond) {
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${bio.bond.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.bonds) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${bio.bonds.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for flaw field
      if (bio.flaw) {
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${bio.flaw.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.flaws) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${bio.flaws.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
      
      // Check for backstory field
      if (bio.backstory) {
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${bio.backstory.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      } else if (bio.background) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${bio.background.replace(/<\/?[^>]+(>|$)/g, " ")}</div>
          </div>
        `;
      }
    } else {
      html += '<div class="no-items">No biography information found</div>';
    }
    
    html += '</div>'; // End biography section
    return html;
  };

  // Handle image click to open in modal
  const handleImageClick = (image) => {
    const index = galleryImages.findIndex(img => img.url === image.url);
    setCurrentImageIndex(index);
    setSelectedImage(image);
    
    // Enable pinch zoom by modifying the viewport meta tag
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes');
    }
  };

  // Close the image modal
  const handleCloseModal = () => {
    setSelectedImage(null);
    
    // Reset viewport meta tag when closing the modal
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');
    }
  };

  // Handle next image in gallery
  const handleNextImage = () => {
    if (galleryImages.length <= 1) return;
    const nextIndex = (currentImageIndex + 1) % galleryImages.length;
    setCurrentImageIndex(nextIndex);
    setSelectedImage(galleryImages[nextIndex]);
  };

  // Handle previous image in gallery
  const handlePrevImage = () => {
    if (galleryImages.length <= 1) return;
    const prevIndex = (currentImageIndex - 1 + galleryImages.length) % galleryImages.length;
    setCurrentImageIndex(prevIndex);
    setSelectedImage(galleryImages[prevIndex]);
  };

  // Add CSS styles for character details grid layout and ability boxes
  useEffect(() => {
    // Create and append style element
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .character-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 1.5rem;
      }
      
      .detail-box {
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid #8B0000;
        padding: 0.5rem;
        text-align: center;
        border-radius: 4px;
      }
      
      .detail-label {
        font-weight: bold;
        color: #ffd700;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
        font-size: 0.9rem;
      }
      
      .detail-value {
        color: #fff;
        font-size: 1rem;
        word-break: break-word;
      }
      
      /* Ability score styling */
      .abilities-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 1.5rem;
      }
      
      .ability-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        border: 1px solid #8B0000;
        border-radius: 4px;
        padding: 5px 5px 10px 5px;
        width: 100%;
        box-sizing: border-box;
      }
      
      .ability-name {
        font-weight: bold;
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 5px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #8B0000;
        padding-bottom: 5px;
      }
      
      .ability-circle {
        width: 50px;
        height: 50px;
        background-color: #000;
        border: 2px solid #ff0000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0;
      }
      
      .ability-value {
        font-size: 1.4rem;
        color: #fff;
        font-weight: bold;
      }
      
      .ability-modifier {
        font-size: 1.2rem;
        color: #fff;
        margin-top: 5px;
      }
      
      .ability-proficient {
        font-size: 0.8rem;
        color: #ffd700;
        margin-top: 5px;
      }
      
      /* Responsive styling */
      @media (max-width: 768px) {
        .character-details-grid {
          grid-template-columns: 1fr;
        }
        
        .abilities-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      
      @media (max-width: 480px) {
        .abilities-container {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    `;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Add handlePasswordChange function before the return statement
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    // Reset states
    setPasswordError('');
    setPasswordSuccess('');
    
    // Validate passwords
    if (newPassword !== confirmPassword) {
      setPasswordError('New passwords do not match');
      return;
    }
    
    if (newPassword.length < 8) {
      setPasswordError('New password must be at least 8 characters long');
      return;
    }
    
    setIsChangingPassword(true);
    
    try {
      // Use the exact endpoint from the server.js file
      const response = await fetch(`${API_URL}/change-password`, {
        method: 'PUT',  // The server uses PUT for this endpoint
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          currentPassword: currentPassword,
          newPassword: newPassword
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        if (response.status === 400) {
          setPasswordError(errorData.message || 'Current password is incorrect');
        } else if (response.status === 401) {
          setPasswordError('Authentication failed. Please log out and log in again.');
        } else {
          setPasswordError(errorData.message || 'Failed to change password');
        }
        return;
      }
      
      // Clear form and show success message
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordSuccess('Password changed successfully!');
      
      // Clear success message after 5 seconds
      setTimeout(() => {
        setPasswordSuccess('');
      }, 5000);
    } catch (err) {
      console.error('Error changing password:', err);
      setPasswordError('Network error. Please try again later.');
    } finally {
      setIsChangingPassword(false);
    }
  };

  // Add keyboard navigation for image gallery
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedImage) return;
      
      switch (e.key) {
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePrevImage();
          break;
        case 'Escape':
          handleCloseModal();
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Clean up event listener on unmount or when selectedImage changes
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImage, currentImageIndex]);

  // Function to fetch admin messages
  const fetchAdminMessages = async () => {
    try {
      const response = await fetch(`${API_URL}/user/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setLocalAdminMessages(data);
      } else {
        console.error('Failed to fetch admin messages');
      }
    } catch (error) {
      console.error('Error fetching admin messages:', error);
    }
  };

  // Add function to toggle message minimized state
  const toggleMessageMinimize = (messageId) => {
    if (minimizedMessages.includes(messageId)) {
      setMinimizedMessages(minimizedMessages.filter(id => id !== messageId));
    } else {
      setMinimizedMessages([...minimizedMessages, messageId]);
    }
  };

  // Main render
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'character' ? 'active' : ''}`}
          onClick={() => handleTabClick('character')}
        >
          Character Sheet
        </button>
        <button 
          className={`tab-button ${activeTab === 'gallery' ? 'active' : ''}`}
          onClick={() => handleTabClick('gallery')}
        >
          Gallery
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => handleTabClick('links')}
        >
          Links
        </button>
        <button 
          className="tab-button logout-tab"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <div className="dashboard-sections">
              <div className="gm-messages">
                <h4>GM Messages</h4>
                {localAdminMessages && localAdminMessages.length > 0 ? (
                  <div className="message-list">
                    {/* Sort messages to show pinned ones first */}
                    {localAdminMessages
                      .sort((a, b) => {
                        // First sort by pinned status (pinned first)
                        if (a.pinned && !b.pinned) return -1;
                        if (!a.pinned && b.pinned) return 1;
                        // Then sort by date (newest first)
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      })
                      .map((message, index) => (
                      <div key={index} className={`gm-message ${message.pinned ? 'pinned' : ''}`}>
                        <div className="message-header">
                          <div className="message-from">
                            {message.subject || 'No Subject'}
                            {message.pinned && <span className="pinned-badge">📌</span>}
                          </div>
                          <div className="message-date">{formatDate(message.createdAt)}</div>
                          <button 
                            className="minimize-button" 
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleMessageMinimize(message.id || index);
                            }}
                          >
                            {minimizedMessages.includes(message.id || index) ? '▼' : '▲'}
                          </button>
                        </div>
                        {!minimizedMessages.includes(message.id || index) && (
                          <div className="message-content message-content-isolated">
                            <iframe 
                              srcDoc={message.content} 
                              src={createIframeSrc(message.content)}
                              frameBorder="0" 
                              scrolling="auto"
                              title="Message content"
                              className="content-iframe"
                              style={{ 
                                width: '100%', 
                                minHeight: '150px',
                                height: '350px', // Reasonable default height
                                overflowY: 'auto' // Allow scrolling within iframe
                              }}
                              onLoad={(e) => {
                                // Add message listener for resize messages from iframe
                                const handleMessage = (event) => {
                                  if (event.data && event.data.type === 'resize') {
                                    e.target.style.height = `${event.data.height + 30}px`; // Add padding
                                  }
                                };
                                window.addEventListener('message', handleMessage);
                                
                                // Try to adjust height directly
                                try {
                                  const iframe = e.target;
                                  const resizeIframe = () => {
                                    try {
                                      const height = iframe.contentWindow.document.body.scrollHeight;
                                      iframe.style.height = `${height + 30}px`; // Add padding
                                    } catch (err) {
                                      console.error('Error adjusting iframe height:', err);
                                    }
                                  };
                                  resizeIframe();
                                  
                                  // Try again after short delay to allow images to load
                                  setTimeout(resizeIframe, 300);
                                  setTimeout(resizeIframe, 1000);
                                } catch (err) {
                                  console.error('Error resizing iframe:', err);
                                }
                              }}
                            ></iframe>
                          </div>
                        )}
                        {minimizedMessages.includes(message.id || index) && (
                          <div className="message-subject">
                            <span className="sender">Admin</span> - {message.subject || 'No Subject'}
                            {message.pinned && <span className="pinned-badge small">📌</span>}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-messages">
                    <p>No messages from GM.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'gallery' && (
          <div className="gallery-tab">
            {isLoadingGallery ? (
              <div className="loading-gallery">
                <p>Loading gallery...</p>
              </div>
            ) : galleryError ? (
              <div className="gallery-error">
                <p>{galleryError}</p>
                <button onClick={currentFolder ? () => fetchFolderImages(currentFolder.category, currentFolder.path) : fetchGalleryCategories} className="retry-button">
                  Retry
                </button>
              </div>
            ) : currentFolder ? (
              // Show images in the current folder
              <>
                <div className="gallery-header">
                  <button className="back-button" onClick={handleBackToFolders}>
                    &larr; Back to Categories
                  </button>
                  <h4>{currentFolder.category.charAt(0).toUpperCase() + currentFolder.category.slice(1)} / {currentFolder.path}</h4>
                </div>
                
                {galleryImages.length === 0 ? (
                  <div className="no-images">
                    <p>No images found in this folder.</p>
                  </div>
                ) : (
                  <div className="gallery-grid">
                    {galleryImages.map((image, index) => {
                      console.log(`Rendering image ${index}:`, image.url);
                      return (
                        <div 
                          key={`image-${index}`} 
                          className="gallery-item"
                          onClick={() => handleImageClick(image)}
                        >
                          <img 
                            src={image.url} 
                            alt={image.name} 
                            onLoad={() => console.log(`Image loaded successfully: ${image.url}`)}
                            onError={(e) => {
                              console.error(`Failed to load image: ${image.url}`);
                              e.target.src = '/placeholder-image.svg';
                              e.target.alt = 'Image not found';
                            }} 
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              // Show categories and their folders
              galleryCategories.length === 0 ? (
                <div className="no-folders">
                  <p>No categories available in the gallery.</p>
                </div>
              ) : (
                <>
                  <div className="gallery-categories">
                    {/* Display each category with its folders */}
                    {galleryCategories.map(category => {
                      if (category.folders.length === 0) return null;
                      
                      return (
                        <div key={category.path} className="gallery-category">
                          <h4>{category.name}</h4>
                          <div className="folders-grid">
                            {category.folders.map((folder, index) => (
                              <div 
                                key={`folder-${index}`} 
                                className="folder-item"
                                onClick={() => handleFolderClick(category.path, folder)}
                              >
                                <div className="folder-icon">
                                  {folder.isDirectImageFolder ? (
                                    <i className="folder-symbol">🖼️</i>
                                  ) : (
                                    <i className="folder-symbol">📁</i>
                                  )}
                                </div>
                                <div className="folder-name">
                                  {folder.name}
                                  {folder.imageCount > 0 && (
                                    <span className="image-count"> ({folder.imageCount})</span>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )
            )}
            
            {selectedImage && (
              <div className="image-modal-overlay" onClick={handleCloseModal}>
                <div className="image-modal-content" onClick={e => e.stopPropagation()}>
                  <img 
                    src={selectedImage.url} 
                    alt={selectedImage.name}
                    className="modal-image"
                    onClick={e => e.stopPropagation()}
                    onTouchStart={(e) => {
                      // Only enable swipe navigation when there are multiple images
                      if (galleryImages.length <= 1) return;
                      
                      // Don't capture touch events if more than one touch point (pinch zoom)
                      if (e.touches.length > 1) return;
                      
                      // Store initial touch position for swiping
                      const touchDown = e.touches[0].clientX;
                      e.currentTarget.dataset.touchStartX = touchDown;
                      // Reset the navigation flag to allow a new swipe
                      e.currentTarget.dataset.hasNavigated = "false";
                    }}
                    onTouchMove={(e) => {
                      // Skip if no starting position recorded or only one image
                      // Also skip if multiple touch points (pinch zoom)
                      if (!e.currentTarget.dataset.touchStartX || 
                          galleryImages.length <= 1 || 
                          e.touches.length > 1 ||
                          e.currentTarget.dataset.hasNavigated === "true") return;
                      
                      const touchDown = parseInt(e.currentTarget.dataset.touchStartX);
                      const currentTouch = e.touches[0].clientX;
                      const diff = touchDown - currentTouch;
                      
                      // Threshold for swipe detection - at least 50px movement
                      if (diff > 50) {
                        // Swiped left - go to next
                        e.currentTarget.dataset.touchStartX = 0;
                        // Set navigated flag to prevent multiple navigations per swipe
                        e.currentTarget.dataset.hasNavigated = "true";
                        handleNextImage();
                      }
                      
                      if (diff < -50) {
                        // Swiped right - go to previous
                        e.currentTarget.dataset.touchStartX = 0;
                        // Set navigated flag to prevent multiple navigations per swipe
                        e.currentTarget.dataset.hasNavigated = "true";
                        handlePrevImage();
                      }
                    }}
                    onTouchEnd={(e) => {
                      // Clear the touch start reference and navigation flag
                      e.currentTarget.dataset.touchStartX = 0;
                      e.currentTarget.dataset.hasNavigated = "false";
                    }}
                    onTouchCancel={(e) => {
                      // Also reset on touch cancel events (e.g., when a system dialog appears)
                      e.currentTarget.dataset.touchStartX = 0;
                      e.currentTarget.dataset.hasNavigated = "false";
                    }}
                    onError={(e) => {
                      console.error(`Failed to load image in modal: ${selectedImage.url}`);
                      e.target.src = '/placeholder-image.svg';
                      e.target.alt = 'Image not found';
                    }} 
                  />
                  
                  <button 
                    className="modal-close-button"
                    onClick={handleCloseModal}
                    aria-label="Close"
                  >
                    ✕
                  </button>
                  
                  <a 
                    className="modal-download-button" 
                    href={selectedImage.url} 
                    download={selectedImage.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                    aria-label="Download"
                  >
                    ↓
                  </a>
                  
                  {galleryImages.length > 1 && (
                    <>
                      <button 
                        className="modal-prev-button" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrevImage();
                        }}
                        aria-label="Previous image"
                      >
                        &#10094;
                      </button>
                      <button 
                        className="modal-next-button" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNextImage();
                        }}
                        aria-label="Next image"
                      >
                        &#10095;
                      </button>
                    </>
                  )}
                  
                  <div className="modal-image-counter">
                    {galleryImages.length > 0 ? `${currentImageIndex + 1} / ${galleryImages.length}` : ''}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'character' && (
          <div className="character-sheet-tab">
            {renderCharacterSheet()}
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Your avatar" />
                ) : (
                  <div className="avatar-placeholder">
                    <span>{currentUser?.displayName?.[0] || currentUser?.email?.[0] || '?'}</span>
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Update Avatar</h4>
                <p>Upload a new avatar image (max 2MB).</p>
                
                <label className="upload-button">
                  Choose Image
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {isUploading && <div className="loading-spinner"></div>}
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Name</div>
                <div className="info-value">{currentUser?.displayName || 'Not set'}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Email</div>
                <div className="info-value">{currentUser?.email}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser?.createdAt)}</div>
              </div>
            </div>
            
            <div className="password-section">
              <h4>Change Password</h4>
              <form onSubmit={handlePasswordChange} className="password-form">
                {passwordError && <div className="password-error">{passwordError}</div>}
                {passwordSuccess && <div className="password-success">{passwordSuccess}</div>}
                
                <div className="password-field">
                  <label>Current Password</label>
                  <input
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </div>
                
                <div className="password-field">
                  <label>New Password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    minLength={8}
                  />
                </div>
                
                <div className="password-field">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength={8}
                  />
                </div>
                
                <button 
                  type="submit" 
                  className="change-password-btn"
                  disabled={isChangingPassword}
                >
                  {isChangingPassword ? 'Changing Password...' : 'Change Password'}
                </button>
              </form>
            </div>
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <div className="links-container">
              {/* DO NOT REMOVE THESE LINKS! THEY ARE REQUIRED!
                  IMPORTANT: THESE TWO LINKS MUST ALWAYS BE PRESENT!
                  THESE LINKS WERE SPECIFICALLY REQUESTED BY USER! */}
              <div className="link-card">
                <a
                  href="https://discord.gg/SGMCCwsd"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <a
                  href="http://47.7.21.116:30000/join"
                  className="link-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Launch Foundry
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 